// @flow

import React from "react";
import styled from "@emotion/styled";

import useGroups from "../hooks/use-groups";
import Header from "../components/Header";
import Layout from "../layouts/layout";
import MainGallery from "../components/MainGallery";

const InfoBoxStyled = styled("div")`
  margin-top: 93px;
  background: #fddb5d;
  font-size: 16px;
  font-weight: bold;
  line-height: 29px;

  @media (max-width: 769px) {
    margin-top: 20px;
  }
`;

function Groups() {
  const groups = useGroups();

  return (
    <Layout title="Zespoły">
      <div className="row">
        <main className="col-12 col-md-8">
          <Header>Zespoły</Header>
          <MainGallery className="mt-5" groups={groups} />
        </main>
        <aside className="col-12 col-md-4">
          {false && <Header>Pozostałe zespoły</Header>}
          <InfoBoxStyled className="p-4">
            Jeśli chcesz dodać zespół, napisz do nas promocja@gok.psary.pl
          </InfoBoxStyled>
        </aside>
      </div>
    </Layout>
  );
}

export default Groups;
